import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: '4500 Wisconsin — Washington, D.C' },
    { img: data.i2, caption: '4500 Wisconsin — Washington, D.C' },
    { img: data.i3, caption: 'Burnham Square — Columbus, Ohio' },
    { img: data.i4, caption: 'Burnham Square — Columbus, Ohio' },
    { img: data.i5, caption: 'Fourth at Glen Iris — Atlanta, Georgia' },
    { img: data.i6, caption: 'Fourth at Glen Iris — Atlanta, Georgia' },
    { img: data.i7, caption: 'Gables Midtown — Atlanta, Georgia' },
    { img: data.i8, caption: 'Gables Midtown — Atlanta, Georgia' },
    { img: data.i9, caption: '5th & Peachtree — Atlanta, Georgia' },
    { img: data.i10, caption: '5th & Peachtree — Atlanta, Georgia' },
    { img: data.i11, caption: 'Shafer & Grace — Richmond, Virginia' },
    { img: data.i12, caption: 'Shafer & Grace — Richmond, Virginia' },
    { img: data.i13, caption: 'Theo at 9th & Colorado — Denver, Colorado' },
    { img: data.i14, caption: 'Theo at 9th & Colorado — Denver, Colorado' },
  ];

  return (
    <Layout>
      <SEO title="Gallery: Apartments and Condos" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>Gallery</h2>
        <h1 className='title title--xxxl color-primary'>Apartments & Condos</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>Since 1986, The Infinity Structural System has remained the ideal choice for
          mid-to-high-rise apartments, condos and urban loft projects up to 10-Stories. The infinity
          System is faster and less expensive than traditional concrete, pre-cast or structural
          steel frames. Furthermore, it provides a high quality, non-combustible, low maintenance
          building with excellent STC and IIC ratings and a very long building lifespan.</p>

          <p>In most cases, high-density urban infill sites require parking under the
          building, where the Infinity System offers many advantages to the Owner and
          Architect. The residential units can be designed efficiently without the constraint
          of matching column locations with the parking deck layout, and the floor plan won’t
          have unsightly column bump-outs that interfere with furniture placement.&nbsp;
          Unrestricted column locations allow the parking areas under the building to be
          efficiently designed with the maximum number of parking spaces. The upper level
          of the parking structure (or retail area) is designed as a transfer slab to
          distribute the loads of the Infinity System to the columns in the parking area.</p>

          <p>There are virtually no restrictions on what exterior skin may be utilized on
          a building with the Infinity System. The architect can specify brick, stone,
          stucco, EIFS, vinyl or other types of siding.&nbsp; One of the advantages of
          our EPICORE MSR Floor System is the fact that the floor slab is only 5” to 6”
          thick. This allows you to maximize your ceiling heights in the units while still
          maintaining minimum floor-to-floor height which is imperative if there are building
          height restrictions. The reduced building height significantly lowers the cost of
          exterior skin, sheathing, drywall, metal studs, stairs, piping, wiring, etc.</p>

          <p>One of the primary reasons Developers and Owners insist on using the Infinity
          System is the Quality of their completed building, plus the tremendous cost
          savings. The EPICORE MSR Floor system provides a very rigid floor slab that does
          not bounce and vibrate like a joist-type floor system. We have engaged independent
          Acoustical Labs to test our EPICORE MSR Floor System for sound ratings using
          various combinations of floor coverings, underlayments, and ceiling treatments.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
         query {
           i1: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/01-Wisco010_(40000).png" }) {
             ...GalleryCarouselImageFragment
           }
           i2: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/02-Wisco040_(40938).png" }) {
             ...GalleryCarouselImageFragment
           }
           i3: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/03-BurnS004_(40251).png" }) {
             ...GalleryCarouselImageFragment
           }
           i4: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/04-BurnS040_(40608).png" }) {
             ...GalleryCarouselImageFragment
           }
           i5: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/05-FourG030_(41267).png" }) {
             ...GalleryCarouselImageFragment
           }
           i6: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/06-FourG040_(41977).png" }) {
             ...GalleryCarouselImageFragment
           }
           i7: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/07-GABLE050_(41306).png" }) {
             ...GalleryCarouselImageFragment
           }
           i8: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/08-GABLE170_(41979).png" }) {
             ...GalleryCarouselImageFragment
           }
           i9: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/09-5thPT010_(42828).png" }) {
             ...GalleryCarouselImageFragment
           }
           i10: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/10-5thPT050_(43259).png" }) {
             ...GalleryCarouselImageFragment
           }
           i11: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/11-Sha_and_G010_(42982).png" }) {
             ...GalleryCarouselImageFragment
           }
           i12: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/12-Sha_and_G030_(42984).png" }) {
             ...GalleryCarouselImageFragment
           }
           i13: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/13-9thCO010_(43055).png" }) {
             ...GalleryCarouselImageFragment
           }
           i14: file(relativePath: { eq: "gallery/apartments-and-condos/gallery/14-Theo_9th+CO-_Finished2_(NEW).JPG" }) {
             ...GalleryCarouselImageFragment
           }
         }
       `
